import styled from "styled-components";

export const AssistantMicWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 35%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const UserActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: -webkit-fill-available;
  flex-direction: column;
  align-items: center;
`;

export const EndSpeakingWording = styled.p`
  font-size: 80px !important;
  font-weight: 900 !important;
  color: #fff;
  height: 100px;
  text-align: center;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
`;
